import '~/scripts/integrations/jquery-extended';

window.R.recognitions.PointsRemaining = (function() {
  'use strict';

  var PointsRemaining = function($listWrapper) {
    this.addEvents();
  };

  PointsRemaining.prototype.addEvents = function() {
    $document.on("removedRecipient", this.calculatePoints.bind(this));
    $document.on("addedRecipient", this.calculatePoints.bind(this));
    $window.on('badgeAdded', this.badgeAdded.bind(this));
  };

  PointsRemaining.prototype.getRecipientCount = function () {
    var count = 0;

    for (var r in R.currentPage.recipients) {
      var item = R.currentPage.recipients[r];

      // 1. 'User' type is for normal recipient selection
      // 2. 'email' type is for new recipient by email
      // 3. 'user' & 'externalUser' type is for outlook
      //    outlook also has 'distributionList' type for groups (along with 'other'), but ignoring that for now,
      //    as it requires mapping the team to recognize's team and then fetch our member count
      //    https://learn.microsoft.com/en-us/javascript/api/outlook/office.mailboxenums.recipienttype?view=outlook-js-preview#fields
      if (["User", "user", "externalUser", "email"].includes(item.type)) {
        count++;
      } else {
        if (item.count) {
          count += item.count;
        } else {
          return null; // unknown recipient type (eg. could be an outlook group)
        }
      }
    }

    return count;
  };

  PointsRemaining.prototype.badgeAdded = function(e, data) {
    this.badgePoints = data.points;
    this.calculatePoints();
  };

  PointsRemaining.prototype.calculatePoints = function() {
    var recipientCount, totalPoints;

    if (this.badgePoints === null || typeof(this.badgePoints)==='undefined') {return;}

    recipientCount = this.getRecipientCount();
    if (typeof recipientCount !== 'number') {return;}

    totalPoints = recipientCount * this.badgePoints;

    $window.trigger('updatePointsRemaining', {points: totalPoints});
  };

  return PointsRemaining;
})();
